.affix_box {
    position: fixed;
    left: 50%;
    bottom: 30px;
    display: block;
    margin-left: 520px;
    width: 50px;
    z-index: 1000;
    & table {
        width: 50px;
        border: 0;
        & tr td {
            position: relative;
            & a {
                cursor: pointer;
                display: block;
                height: 50px;
                margin-bottom: 1px;
                background: #ededed;
                text-align: center;
                color: #999999;
                & div {
                    display: block;
                    height: 30px;
                    width: 50px;
                    background: url(./g52a.png) no-repeat;
                }
                & .quote {
                    background-position: left 0px;
                }
                & .funds {
                    background-position: left -51px;
                }
                & .back {
                    background-position: left -102px;
                }
                & p {
                    word-wrap: break-word;
                    text-align: center;
                    color: #999999;
                }
            }
            & a:hover {
                background: #999999;
                color: #fff;
                text-decoration: none;
                & div {
                    display: block;
                    height: 30px;
                    width: 50px;
                    background: url(./g53a.png) no-repeat;
                }
                & .quote {
                    background-position: left 0px;
                }
                & .funds {
                    background-position: left -51px;
                }
                & .back {
                    background-position: left -102px;
                }
                & p {
                    color: #fff;
                }
            }
        }
    }
}

.caption {
    position: absolute;
    left: -138px;
    top: 0px;
    background: #fff;
    & .search {
        width: 176px;
        height: 42px;
        padding: 10px 0px 0px 12px;
        background: url(./g56.png) no-repeat;
        & input {
            float: left;
            width: 106px;
            height: 30px;
            line-height: 30px;
            border: 0px;
            padding-left: 7px;
            margin-right: 1px;
        }
        & a {
            display: block;
            width: 48px;
            height: 28px;
            line-height: 28px;
            overflow: hidden;
            border: none;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
            float: right;
            text-decoration: none;
            background: none;
        }
    }
}

.show {
    display: block;
}

.hide {
    display: none;
}

.fdockbar {
    position: fixed;
    clear: both;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-family: 'å¾®è½¯é›…é»‘', 'å®‹ä½“', Arial, Verdana;
    font-size: 14px;
    font-weight: bold;
    background-color: #5587b4;
    color: #fff;
}

.floatAd {
    display: none;
}
