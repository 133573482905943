.col {
    width: 650px;
    float: left;
}

.adbox {
    margin-top: 36px;
}

.box650 {
    margin-top: 20px;
}
