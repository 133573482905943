.item02 li {
    cursor: pointer;
    float: left;
    width: 290px;
}

.item02 span.num {
    font-family: Arial;
    font-size: 14px;
    color: #fff;
    text-align: center;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: #666;
    float: left;
    margin-right: 5px;
}

.item02 span {
    float: left;
}

.item02 span.per {
    font-family: Arial;
    font-size: 14px;
    color: #222;
    height: 20px;
    line-height: 20px;
    padding-left: 10px;
}

.item02 li p {
    display: block;
}

.item02 h3 a:hover {
    color: #f06;
}

.item02 p {
    clear: both;
    color: #999;
    line-height: 18px;
    padding: 7px 0;
}
