.wrap {
    width: 1000px;
    margin: 20px auto 36px;
}

.tit01 {
    margin-bottom: 44px;
    height: 65px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/titBg_0df47071.jpg) repeat-x;
}

.t_name {
    font-family: Microsoft YaHei;
    font-size: 24px;
    color: #ddd;
    height: 65px;
    line-height: 65px;
    width: 167px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/t2_0_827fce50.jpg) no-repeat;
    margin: 0 auto;
}

.t_name .s01 {
    color: #000;
    padding-left: 59px;
}

.t_name .s01 a {
    color: #000;
}

.t_name .s02 {
    color: #fd0467;
}

.t_name .s02 a {
    color: #fd0467;
}

.t_name .s01 a:hover,
.t_name .s02 a:hover {
    text-decoration: none;
}

.sliderContainer {
    height: 36px;
    padding-left: 18px;
    *padding-left: 10px;
    margin-top: 8px;
    overflow: hidden;
    composes: clearfix from global;
}

/* 可切换标签样式 */

.wrap {
    & :global([ifeng_ui_pc_sliders-dots]) {
        height: 36px;
        padding-left: 18px;
        *padding-left: 10px;
        margin-top: 8px;
        overflow: hidden;
        top: -44px;
    }

    & .dot {
        width: auto;
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #222;
        height: 34px;
        line-height: 34px;
        border: 1px solid #d7d7d7;
        padding: 0 20px;
        border-radius: 20px;
        float: left;
        margin-right: 20px;
        cursor: pointer;
    }

    & .dot a {
        color: #222;
    }

    & a,
    & a:hover {
        text-decoration: none;
        color: #f06;
    }

    & .current {
        font-family: Microsoft YaHei;
        width: auto;

        font-size: 16px;

        height: 34px;
        line-height: 34px;

        padding: 0 20px;
        border-radius: 20px;
        float: left;
        margin-right: 28px;
        cursor: pointer;
        font-weight: bold;
        color: #fff;
        background: #f06;
        border: 1px solid #f06;
    }

    & .current a,
    & .current a:hover {
        color: #fff;
    }
}
