.trans {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.box {
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/line3_a2ea01e2.png) no-repeat left bottom;
    padding: 24px 0 25px;
    *padding: 20px 0 21px;
    _padding: 19px 0 20px;
    position: relative;
    padding: 15px 0 20px;
}

.pic {
    width: 140px;
    height: 95px;
    overflow: hidden;
    float: left;
}

.txt {
    width: 494px;
    float: right;
}

.box h3 {
    font-family: Microsoft YaHei;
    font-size: 18px;
    color: #222;
    font-weight: bold;
    height: 24px;
    line-height: 24px;

    & a:hover {
        color: #f06;
        text-decoration: none;
    }
}

.box h3 a {
    color: #222;
}

.box h3 img {
    display: inline;
    padding-left: 10px;
}

.box p {
    color: #999;
    line-height: 18px;
    height: 36px;
    margin-top: 8px;
    overflow: hidden;
}

.date {
    font-family: Arial;
    color: #c2c2c2;
    height: 20px;
    line-height: 20px;
    margin-top: 6px;
}

.date span {
    margin-right: 20px;
}

.date .sTs {
    font-family: Simsun;
    color: #ff8cb9;
    margin-right: 0;
}

.date .sTs a {
    color: #ff8cb9;
    border: 1px solid #ff8cb9;
    border-radius: 5px;
    padding: 1px 4px;
}

.date .sTs a:hover {
    color: #f06;
    border: 1px solid #f06;
}

.imgList {
    width: 650px;
    overflow: hidden;
}

.box ul {
    width: 660px;
    margin-top: 7px;
    overflow: hidden;
}

.box ul li {
    width: 210px;
    height: 135px;
    background: 0;
    padding: 0;
    margin: 0;
    margin-right: 10px;
    float: left;
    overflow: hidden;
}

.box ul a:hover img {
    transform: scale(1.1, 1.1);
}

.box ul .lbn {
    padding-bottom: 0;
    background: 0;
}
