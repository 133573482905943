.trans {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-backface-visibility: hidden;
}

.pt2 {
    margin-top: 20px;
    composes: clearfix from global;

    & a:hover img {
        transform: scale(1.1, 1.1);
    }
}

.list05 {
    width: 220px;
    height: 466px;
    float: left;
    overflow: hidden;
}

.list05 li {
    margin-bottom: 40px;
}

.list05 span {
    display: block;
    font-family: Arial;
    font-size: 16px;
    color: #222;
    text-align: center;
    width: 78px;
    border-bottom: 3px solid #dcdcdc;
    padding-bottom: 7px;
    margin: 0 auto;
}

.list05 span a {
    color: #222;
}

.list05 .pic {
    margin-top: 15px;
}

.list05 p {
    font-family: Microsoft YaHei;
    font-size: 16px;
    color: #222;
    height: 22px;
    line-height: 22px;
    overflow: hidden;
    margin-top: 8px;
}

.list05 p a {
    color: #222;
}

.zhPic {
    width: 502px;
    height: 466px;
    text-align: center;
    border-left: 9px solid #e7e7e7;
    border-right: 9px solid #e7e7e7;
    margin: 0 20px;
    float: left;
}

.zhPic .name {
    font-family: Georgia;
    font-size: 14px;
    color: #666;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/line2_c30bdaea.png) no-repeat top center;
    padding-top: 10px;
    margin-top: 20px;
}

.zhPic .name a {
    color: #666;
}

.zhPic .pic {
    border-bottom: 1px solid #e7e7e7;
    width: 502px;
    height: 324px;
    overflow: hidden;
}

.zhPic span {
    display: block;
    font-family: Arial;
    font-size: 16px;
    color: #222;
    font-weight: bold;
    margin-top: 10px;
}

.zhPic span a {
    color: #222;
}

.zhPic h3 {
    font-family: Microsoft YaHei;
    font-size: 20px;
    color: #222;
    font-weight: bold;
    height: 26px;
    line-height: 26px;
    margin-top: 4px;
    overflow: hidden;
}

.zhPic h3 a {
    color: #222;
}

.zhPicW {
    width: 742px;
    margin-left: 0;
    overflow: hidden;
}

.zhPicW .pic {
    width: 742px;
    height: 324px;
    overflow: hidden;
}
