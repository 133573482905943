.list12 {
    width: 300px;
    margin-top: 36px;
}

.pt12 {
    width: 300px;
    position: relative;
}

.videoBtn2 {
    width: 65px;
    height: 65px;
    position: absolute;
    left: 50%;
    top: 68px;
    margin-left: -32px;
    background: url(https://p0.ifengimg.com/fe/liveroom/images/p-icon-video_ec2f143b_ec2f143b.png) no-repeat 0 0;
    z-index: 4;
    cursor: pointer;
}

.videoBtn2 a {
    display: block;
    width: 65px;
    height: 65px;
}

.pic {
    width: 300px;
    height: 200px;
}

.videoZz2 {
    width: 300px;
    height: 200px;
    position: absolute;
    left: 0;
    top: 0;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/videoZz2_8ab2c5d2.png) no-repeat;
    z-index: 3;
    cursor: pointer;
}

.videoZz2 a {
    display: block;
    width: 300px;
    height: 200px;
}

.pt12 p {
    font-size: 14px;
    color: #222;
    font-weight: bold;
    height: 24px;
    line-height: 24px;
    margin-top: 6px;
    overflow: hidden;

    & a:hover {
        color: #f06;
        text-decoration: none;
    }
}

.pt12 p a {
    color: #222;
}

.list12 ul {
    margin-top: 6px;
}

.list12 li {
    color: #222;
    height: 24px;
    line-height: 24px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/vBtn_04437ace.png) no-repeat left center;
    padding-left: 20px;
    overflow: hidden;

    & a:hover {
        color: #f06;
        text-decoration: none;
    }
}

.list12 li a {
    color: #222;
}
