.titR {
    color: #222;
    height: 45px;
    line-height: 45px;
    background: #f4f4f4 url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/titBg_20177f09.png) no-repeat left center;
    padding: 0 10px 0 16px;
}

.titR strong {
    font-family: Microsoft YaHei;
    font-weight: normal;
    font-size: 18px;
}

.titR span {
    font-family: Arial;
    color: #222;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/more2_f4b54423.png) no-repeat right center;
    padding-right: 10px;
    float: right;
}

.titR a {
    color: #222;
}
