.research {
    margin-top: 20px;
    overflow: hidden;
    composes: clearfix from global;
}

.research h3 {
    font-size: 14px;
    line-height: 18px;
    padding: 5px 0 15px;
}

.research h3 a {
    color: #222;
}

.vote {
    float: left;
    width: 290px;
    height: 36px;
    overflow: hidden;
}

.voteEnd h4 {
    width: 204px;
    height: 36px;
    line-height: 36px;
    background: #262626;
    font-size: 14px;
    text-align: center;
    float: left;
    margin-right: 12px;
}

.vote h4 {
    width: 136px;
    height: 36px;
    line-height: 36px;
    background: #262626;
    font-size: 14px;
    text-align: center;
    float: left;
    margin-right: 12px;
}

.voteEnd h4 a,
.vote h4 a {
    display: block;

    height: 36px;
    color: #fff;
}

.vote p {
    width: 140px;
    height: 36px;
    line-height: 36px;
    color: #666;
    float: left;
}

.vote span {
    font-family: Georgia;
    font-size: 16px;
    color: #f54343;
}

.voteEnd {
    height: 36px;
}

.voteEnd span {
    height: 36px;
    line-height: 36px;
    color: #666;
    float: left;
}
