.trans {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.scroll3 {
    width: 300px;
    margin-top: 20px;

    & :global([ifeng_ui_pc_sliders-dots]) {
        height: 10px;
        bottom: -20px;
        text-align: center;
    }

    & .dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/sb02_12f4ab7d.png) 0 0 no-repeat;

        margin-right: 16px;
    }

    & .current {
        display: inline-block;

        margin-right: 16px;
        width: 8px;
        height: 8px;
        background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/sb02_h_81117ebd.png) no-repeat;
    }
}

.pt3 {
    width: 300px;
    height: 225px;
    position: relative;
    overflow: hidden;
}

.pt3 a:hover img {
    transform: scale(1.1, 1.1);
}

.text {
    width: 240px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #222;
    text-align: center;
    position: absolute;
    left: 30px;
    top: 185px;
    background: #fff;
}

.text a {
    color: #222;
    white-space: nowrap;
    overflow: hidden;
}

.btn3 {
    margin: 12px 0 0 96px;
}
