.trans {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.list07 {
    width: 650px;
    overflow: hidden;
    margin-top: 20px;
}

.list07 ul {
    width: 660px;
    overflow: hidden;
}

.list07 li {
    width: 210px;
    height: 375px;
    margin-right: 10px;
    overflow: hidden;
    float: left;
    position: relative;
}

.textBg {
    width: 210px;
    height: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #000;
    filter: alpha(opacity=60);
    opacity: 0.6;
}

.text {
    font-family: Microsoft YaHei;
    font-size: 18px;
    color: #fff;
    width: 190px;
    height: 0;
    line-height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid #f06;
    padding: 0 10px;
    /* display: none; */
}

.textBg,
.text {
    composes: trans;
}

.text a:hover {
    color: #f06;
}

.li {
}

.hoverLi {
    & .textBg {
        height: 41px;
    }

    & .text {
        height: 40px;
    }
}

.text a {
    color: #fff;
}

.list07 a:hover img {
    transform: scale(1.1, 1.1);
}

.list08 {
    width: 650px;
    margin-top: 20px;
    overflow: hidden;
}

.list08 ul {
    width: 720px;
    overflow: hidden;
}

.list08 li {
    font-size: 14px;
    color: #222;
    height: 28px;
    line-height: 28px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/dian1_429ba987.png) no-repeat left center;
    padding-left: 10px;
    width: 280px;
    float: left;
    margin-right: 70px;
    overflow: hidden;
}

.list08 li a {
    color: #222;
}

.list08 li a:hover {
    color: #f06;
}
