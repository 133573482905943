.more {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #f3f3f3 url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/more_dd42dfd3.png) no-repeat 365px center;
    text-align: center;
    margin-top: 24px;
}

.more a {
    display: block;
    width: 100%;
    height: 40px;
    color: #222;
}

.more a:hover {
    text-decoration: none;
}
