.col {
    width: 300px;
    float: right;
    margin-top: 16px;
}

.mt50 {
    margin-top: 50px;
}

.mt36 {
    margin-top: 36px;
}

.mt33 {
    margin-top: 33px;
}
