.trans {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.li {
    width: 310px;
    height: 232px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
}

.t0 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}

.textBg {
    width: 310px;
    height: 66px;
    line-height: 66;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #000;
    filter: alpha(opacity=60);
    opacity: 0.6;
    z-index: 3;
    composes: trans;
}

.text {
    width: 290px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 4;
}

.textA:hover {
    text-decoration: none;
}

.textA {
    display: block;
    width: 290px;
    height: 50px;
    padding: 8px 10px;
    composes: trans;
}

.li h2 a {
    color: #fff;
}

.titleH2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Microsoft YaHei;
    font-size: 18px;
    color: #fff;
    height: 24px;
    line-height: 24px;
}

.hoverBg {
    & .textA {
        padding-bottom: 91px;
    }

    & .textBg {
        height: 232px;
    }
}

.hoverTitleH2 {
    font-family: Microsoft YaHei;
    font-size: 18px;
    line-height: 24px;
    color: #f06;
    height: auto;
    white-space: normal;
    overflow: hidden;
    text-overflow: initial;
    composes: trans;
}

.line {
    color: #cacaca;
    height: 20px;
    line-height: 20px;
    margin-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hoverLine {
    color: #cacaca;
    line-height: 20px;
    margin-top: 6px;
    height: 80px;
    white-space: normal;
    overflow: hidden;
    text-overflow: initial;
    composes: trans;
}
