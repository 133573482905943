.ad {
    width: 1000px;
    margin: 37px auto 0;
}

.box {
    width: 1000px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    margin: 0;
}

.footerbox {
    background: #262626;
    width: 100%;
    margin: 0 auto;
    color: #fff;

    & span {
        color: #fff;
    }
}

.footerbox a,
.footerbox a:visited,
.footerbox a:link {
    color: #fff !important;
}

:global(.fashionIndexDarkFooter .foot_link) {
    border-top: none !important;
}
