.col {
    width: 650px;
    float: left;
}

.mt36 {
    margin-top: 36px;
}

.box {
    width: 310px;
    float: left;
}

.left {
    composes: box;
    margin-right: 30px;
}

.right {
    composes: box;
}
