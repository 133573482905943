.trans {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-backface-visibility: hidden;
}

.list13 {
    margin-top: 20px;
    composes: clearfix from global;
}

.box0 a:hover img {
    transform: scale(1.1, 1.1);
}

.box199 {
    width: 199px;
    float: left;
}

.box199_2 {
    height: 262px;
    overflow: hidden;
}

.box200 {
    width: 200px;
    height: 525px;
    overflow: hidden;
}

.box399 {
    width: 399px;
}

.box399_2 {
    height: 262px;
    overflow: hidden;
}

.mr01 {
    margin-right: 1px;
}

.mt01 {
    margin-top: 1px;
}

.box0 {
    position: relative;
}

.textBg {
    width: 100%;
    height: 38px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #000;
    filter: alpha(opacity=75);
    opacity: 0.75;
}

.text {
    width: 94%;
    height: 38px;
    line-height: 38px;
    font-family: Microsoft YaHei;
    font-size: 16px;
    color: #fff;
    padding: 0 3%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.text a {
    color: #fff;
}

.textBg,
.text {
    height: 0;
    composes: trans;
}

.text a:hover {
    color: #f06;
}

.clearfix {
    zoom: 1;
}

.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.fl {
    float: left;
}

.li {
}

.hoverLi {
    & .textBg,
    & .text {
        height: 38px;
    }
}
