.list03 {
    margin-top: 30px;
}

.list03 li {
    border-bottom: 1px solid #efefef;
    padding: 14px 0 18px;
}

.list03 h3 {
    font-size: 14px;
    color: #222;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
}

.list03 h3 a {
    color: #222;
}

.list03 h3 a:hover {
    color: #f06;
    text-decoration: none;
}

.pictxt {
    position: relative;
    margin-top: 10px;
}

.pic,
.pic a {
    width: 140px;
    height: 85px;
    float: left;
    overflow: hidden;
}

.pic a:hover {
    & img {
        transform: scale(1.1, 1.1);
    }
}

.txt {
    width: 150px;
    color: #666;
    text-align: justify;
    line-height: 22px;
    height: 85px;
    overflow: hidden;
    float: right;
}

.b_qian {
    width: 62px;
    height: 20px;
    line-height: 20px;
    padding-bottom: 5px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/b_qian_98d73a28.png) no-repeat;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 5px;
    top: 56px;
}

.b_qian a {
    color: #fff;
}

.b_qian a:hover {
    text-decoration: none;
}

.trans {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-backface-visibility: hidden;
}
