.survey_title {
    composes: clearfix from global;
    border-top: 7px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    height: 46px;
    font-size: 14px;
    font-weight: bold;
    & h2 {
        float: left;
        width: 100%;
        padding: 15px 10px 18px;
        line-height: 26px;
        font-weight: bold;
        font-size: 16px;
        & a {
            color: #2b2b2b;
            text-decoration: none;
        }
        & a:hover {
            color: #f54343;
        }
    }
}

.count_box {
    float: right;
    font-size: 12px;
    font-weight: normal;
    color: #9f9f9f;
    height: 46px;
    line-height: 46px;
}

.count {
    font-style: normal;
    font: 18px Georgia, Arial, Helvetica, sans-serif;
    color: #f54343;
    margin: 0 10px;
}

.logo {
    font: bold 18px/28px Microsoft YaHei;
    float: left;
    height: 28px;
    padding: 10px 0 0 10px;
    color: #f54343;
}

.img {
    background: url(./survey.png);
    background-size: 28px 28px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    width: 28px;
    height: 28px;
}
