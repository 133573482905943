.list02 {
    width: 310px;
    height: 474px;
    position: relative;
    margin-bottom: 10px;
}

.list02 .t0 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
}

.list02 h2 {
    font-family: Microsoft YaHei;
    font-size: 18px;
    line-height: 26px;
    height: 52px;
    padding: 0 15px;
    position: absolute;
    left: 0;
    top: 271px;
    z-index: 3;
}

.list02 h2 a {
    color: #fff;
}

.list02 .textBg {
    width: 310px;
    height: 474px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/line_4a776e8b.png) repeat-x left bottom;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.list02 ul {
    position: absolute;
    left: 0;
    top: 328px;
    padding: 0 15px;
    z-index: 2;
}

.list02 li {
    font-size: 14px;
    color: #fff;
    height: 46px;
    line-height: 46px;
    border-bottom: 1px solid #4e4f4f;
    overflow: hidden;
}

.list02 li a {
    color: #fff;
}

.list02 a:hover {
    color: #f06;
    text-decoration: none;
}

.list02 li span {
    font-family: Arial;
    font-weight: bold;
    font-style: italic;
    color: #f06;
    padding-right: 6px;
}

.list02 .lbn {
    border: 0;
}
