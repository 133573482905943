.trans {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.item {
    margin-top: 20px;
    composes: clearfix from global;
}

.pic {
    width: 150px;
    height: 100px;
    float: left;
    overflow: hidden;
}

.pic a:hover img {
    transform: scale(1.1, 1.1);
}

.txt {
    width: 132px;
    float: right;
}

.list11 h4 {
    font-size: 14px;
    color: #222;
    line-height: 24px;
    height: 48px;
    overflow: hidden;
}

.list11 h4 a {
    color: #222;
}

.list11 h4 a:hover {
    color: #f06;
}

.list11 p {
    color: #999;
    line-height: 18px;
    height: 36px;
    margin-top: 4px;
    overflow: hidden;
}
