.trans {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.box {
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/line3_a2ea01e2.png) no-repeat left bottom;
    padding: 24px 0 25px;
    *padding: 20px 0 21px;
    _padding: 19px 0 20px;
    position: relative;
    composes: clearfix from global;
}

.pic {
    width: 140px;
    height: 95px;
    overflow: hidden;
    float: left;
}

.pic a:hover img {
    transform: scale(1.1, 1.1);
}

.txt {
    width: 494px;
    float: right;

    & a:hover {
        color: #f06;
        text-decoration: none;
    }
}

.box h3 {
    font-family: Microsoft YaHei;
    font-size: 18px;
    color: #222;
    font-weight: bold;
    /* height: 24px; */
    line-height: 24px;
}

.box h3 a {
    color: #222;
}

.box h3 img {
    display: inline;
    padding-left: 10px;
}

.box p {
    color: #999;
    line-height: 18px;
    height: 36px;
    margin-top: 8px;
    overflow: hidden;
}

.date {
    font-family: Arial;
    color: #c2c2c2;
    height: 20px;
    line-height: 20px;
    margin-top: 6px;
}

.box .date span {
    margin-right: 20px;
}

/* 分享 */

.fxW {
    width: 36px;
    position: absolute;
    right: 0;
    bottom: 20px;
    z-index: 99;

    /* & .shareBox span,
    & .shareBox a {
        width: 34px;
        height: 28px;
        background-image: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/fxBg_b6921976.png);
    } */
}

.fxW .fxBtn {
    width: 36px;
    height: 24px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/fxBg_b6921976.png) no-repeat;
    background-position: -103px -5px;
}

.fxWH {
    cursor: pointer;

    & .shareBox {
        display: block;

        & > div > div > {
            /* width: 100px; */
        }

        & > div > div > div > div {
            /* width: 94px; */
            /* padding: 3px; */
            /* background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/ewmBg_ad7d49cb.png) no-repeat; */

            /* position: absolute;
            z-index: 103;
          
            overflow: hidden; */

            & img {
                /* width: 85px; */
                /* height: 85px; */
            }
        }
    }

    & .fxBtn {
        background-position: -103px -39px;
    }

    /*
    & .shareBox span[data-item='sina'] {
        background-position: 0 -4px
    }

    & .shareBox span[data-item='sina']:hover {
        background-position: 0 -36px
    }

    & .shareBox span[data-item='qqzone'] {
        background-position: -34px -2px
    }

    & .shareBox span[data-item='qqzone']:hover {
        background-position: -34px -36px
    }

    & .shareBox span[data-item='wechat'] {
        background-position: -68px -2px
    }

    & .shareBox span[data-item='wechat']:hover {
        background-position: -68px -36px
    } */
}

.fxTs {
    top: 189px;
}

.clearfix {
    zoom: 1;
}

.shareBox {
    width: 170px;
    _zoom: 1;
    *display: inline;
    position: absolute;
    z-index: 99;
    /* right: 0;
    bottom: 10px; */

    display: none;
    right: 30px;
    bottom: -10px;
}

/* .shareBox a {
    float: left
} */

/* .shareBox {
    width: 34px;
    height: 90px;
    padding: 5px 1px 2px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/shareBoxBg_5ed9ad36.png) no-repeat;

} */
