.trans {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.scroll5 {
    width: 300px;
    margin-top: 20px;

    & :global([ifeng_ui_pc_sliders-dots]) {
        height: 10px;
        bottom: -15px;
        text-align: right;
    }

    & .dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/sb02_12f4ab7d.png) 0 0 no-repeat;

        margin-right: 16px;
    }

    & .current {
        display: inline-block;

        margin-right: 16px;
        width: 8px;
        height: 8px;
        background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/sb02_h_81117ebd.png) no-repeat;
    }
}

.pt5 {
    width: 300px;
    overflow: hidden;
}

.pic {
    width: 300px;
    height: 190px;
    overflow: hidden;
}

.pic img {
    margin: 13px auto 0;
}

.pic a:hover img {
    transform: scale(1.1, 1.1);
}

.text {
    padding: 14px 15px 0;
}

.scroll5 h4 {
    font-size: 14px;
    color: #222;
    font-weight: bold;
}

.scroll5 h4 a {
    color: #222;
}

.scroll5 h4 a:hover {
    color: #f06;
}

.scroll5 p {
    color: #999;
    line-height: 18px;
    height: 36px;
    overflow: hidden;
    margin-top: 8px;
}

.btn5 {
    margin: 12px 0 0 126px;
}

.btn5 li {
    width: 8px;
    height: 8px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/sb02_12f4ab7d.png) no-repeat;
    border-radius: 50%;
    float: left;
    margin-right: 16px;
}

.btn5 .current {
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/sb02_h_81117ebd.png) no-repeat;
}
