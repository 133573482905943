/*scroll*/

.s_ban,
.s_ban_main {
    width: 310px;
    height: 600px;
}

.scroll6 {
    position: relative;

    & :global([ifeng_ui_pc_sliders-dots]) {
        height: 10px;
        bottom: 11px;
        text-align: center;
    }

    & .dot {
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px;
        cursor: pointer;
    }

    & .current {
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px;
        cursor: pointer;
        background: #f54341;
    }
}

.s_ban_main {
    overflow: hidden;
}

.s_ban_main .li {
    position: relative;
    float: left;
}

.s_ban_main .blockA,
.s_ban_main .bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
}

.s_ban_main .bg {
    background: url(./images/zz_bg.png) repeat-x center bottom;
}

.s_ban_main ul a:hover,
.s_ban_txt a:hover {
    text-decoration: none;
}

.scroll6 {
    background: rgba(255, 255, 255, 0.4);
}

.scroll6 {
    width: 300px;
    height: 600px;
    position: relative;
    overflow: hidden;
}

.pt6 {
    width: 300px;
    height: 600px;
    position: relative;
}

.kuang {
    width: 278px;
    height: 578px;
    border: 1px solid #fff;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;
}

.kuang a {
    display: block;
    width: 278px;
    height: 578px;
}

.textBg {
    width: 300px;
    height: 126px;
    position: absolute;
    left: 0;
    bottom: 30px;
    background: #000;
    filter: alpha(opacity=60);
    opacity: 0.6;
    z-index: 2;
}

.text {
    width: 260px;
    height: 110px;
    padding: 8px 20px;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 30px;
    z-index: 2;
}

.text h2 {
    font-family: Microsoft YaHei;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    height: 24px;
    line-height: 24px;
    overflow: hidden;
}

.text h2 a {
    color: #fff;
}

.text h2 a:hover {
    text-decoration: none;
    color: #f06;
}

.text p {
    font-size: 14px;
    color: #cacaca;
    line-height: 22px;
    margin-top: 10px;
}

.sTs {
    color: #fff;
    border-top: 1px solid #f06;
    height: 44px;
    overflow: hidden;
}

.logoF {
    width: 90px;
    padding-top: 15px;
    border-top: 1px solid #f06;
    margin: 4px 0 16px;
}

.btn6 {
    position: absolute;
    height: 10px;
    bottom: 14px;
    left: 114px;
    z-index: 99;
    overflow: hidden;
}

.btn6 li {
    width: 8px;
    height: 8px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/sb02_12f4ab7d.png) no-repeat;
    _background: 0;
    _filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='http://p0.ifengimg.com/fe/fashion_ifeng_com/styles/images/sb02', sizingMethod='crop');
    margin-right: 10px;
    display: inline;
    float: left;
    cursor: pointer;
}

.current {
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/sb02_h_81117ebd.png) no-repeat;
    _background: 0;
    _filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='http://p0.ifengimg.com/fe/fashion_ifeng_com/styles/images/sb02_h', sizingMethod='crop');
}
