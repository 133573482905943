.col_1000 {
    width: 1000px;
    margin: 0 auto;
}

.adbottom {
    width: 1000px;
    margin: 0 auto;
    margin-top: 36px;
}
