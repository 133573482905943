/*scroll*/

.s_ban,
.s_ban_main {
    width: 650px;
    height: 390px;
}

.s_ban {
    width: 650px;
    height: 390px;
    position: relative;

    & :global([ifeng_ui_pc_sliders-arrows_pre]),
    & :global([ifeng_ui_pc_sliders-arrows_next]) {
        width: 50px;
        height: 100px;
        position: absolute;
        top: 144px;
        z-index: 99;
        cursor: pointer;
    }

    & :global([ifeng_ui_pc_sliders-arrows_pre]) {
        left: 0;
        background: url(//p0.ifengimg.com/fe/fashion_ifeng_com/images/btn_l_6136a5ef.png) no-repeat;
    }

    & :global([ifeng_ui_pc_sliders-arrows_pre]:hover),
    & :global([ifeng_ui_pc_sliders-arrows_next]:hover) {
        background-color: transparent;
    }

    & :global([ifeng_ui_pc_sliders-arrows_next]) {
        background: url(//p0.ifengimg.com/fe/fashion_ifeng_com/images/btn_r_f5054b0f.png) no-repeat;
        right: 0;
    }

    & :global([ifeng_ui_pc_sliders-dots]) {
        height: 10px;
        bottom: 11px;
        text-align: center;
    }

    & .dot {
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px;
        cursor: pointer;
    }

    & .current {
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px;
        cursor: pointer;
        background: #f54341;
    }
}

.s_ban_main {
    overflow: hidden;
}

/* 
.s_ban_main ul {
    width: 100%;
}
 */
.s_ban_main .li {
    position: relative;
    float: left;
}

.s_ban_main .blockA,
.s_ban_main .bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    /* width: 100%;
    height: 600px; */
    /* background: url(./images/zz_bg.png) repeat-x center bottom; */
}

.s_ban_main .bg {
    background: url(./images/zz_bg.png) repeat-x center bottom;
}

.s_ban_txt,
.s_ban_txt .text_swiper,
.s_ban_txt .textBg {
    position: absolute;
    width: 650px;
    height: 86px;
}

.s_ban_txt {
    bottom: 0;
    line-height: 28px;
    text-align: center;
}

.s_ban_txt .text_swiper {
    z-index: 2;
}

.s_ban_txt .textBg {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #000;
    filter: alpha(opacity=65);
    -moz-opacity: 0.65;
    -khtml-opacity: 0.65;
    opacity: 0.65;
    z-index: 1;
}

.s_ban_main ul a:hover,
.s_ban_txt a:hover {
    color: #f06;
    text-decoration: none;
}

.s_ban_txt a {
    font-size: 20px;
    color: #fff;
}

.s_ban_txt p {
    display: block;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    color: #fff;
    margin-top: 6px;
    overflow: hidden;
}

.s_ban_txt h2 {
    font-family: Microsoft YaHei;
    margin-top: 9px;
    height: 24px;
    line-height: 24px;
}

.s_ban_txt h2 a {
    display: block;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.s_ban_txt h2 a:hover {
    color: #f06;
}
