.list09 {
    margin-top: 33px;
}

.boxT {
    height: 68px;
    padding: 0 14px;
    background: #f9f9f9;
    overflow: hidden;
}

.boxT span {
    display: block;
    color: #f06;
    height: 20px;
    line-height: 20px;
    margin-top: 10px;
}

.boxT p {
    font-size: 16px;
    color: #222;
    height: 24px;
    line-height: 24px;
    overflow: hidden;
}

.boxT p strong {
    font-family: Microsoft YaHei;
    font-size: 14px;
    padding-right: 6px;
}

.boxB {
    height: 210px;
    position: relative;
}

.textBg {
    width: 300px;
    height: 48px;
    background: #fff;
    filter: alpha(opacity=85);
    opacity: 0.85;
    position: absolute;
    left: 0;
    bottom: 0;
}

.text {
    font-size: 16px;
    color: #222;
    font-weight: bold;
    width: 272px;
    height: 48px;
    line-height: 48px;
    padding: 0 14px;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
}

.text strong {
    font-family: Microsoft YaHei;
    font-size: 14px;
    padding-right: 6px;
}

.text a {
    color: #222;
}

.text a:hover {
    color: #f06;
    text-decoration: none;
}

.tt {
    width: 122px;
    height: 44px;
    line-height: 44px;
    font-family: SimHei;
    font-size: 18px;
    text-align: center;
    position: absolute;
    left: 15px;
    top: 130px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/tBg_1a0af314.png) no-repeat;
    z-index: 3;
}

.tt a {
    display: block;
    color: #fff;
}

.tt a:hover {
    text-decoration: none;
}
