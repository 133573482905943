.col {
    width: 300px;
    float: right;
}

.mt36 {
    margin-top: 36px;
}

.box {
    width: 310px;
    float: left;
}

.left {
    composes: box;
    margin-right: 30px;
}

.right {
    composes: box;
}

.list04 {
    width: 300px;
    margin-top: 12px;
    overflow: hidden;
}
