.wrap {
    width: 1000px;
    margin: 36px auto;
    composes: clearfix from global;
}

.tit01 {
    height: 65px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/titBg_0df47071.jpg) repeat-x;
}

.t_name {
    font-family: Microsoft YaHei;
    font-size: 24px;
    color: #ddd;
    height: 65px;
    line-height: 65px;
    width: 167px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/t2_6_bccc5434.jpg) no-repeat;
    margin: 0 auto;
}

.t_name .s01 {
    color: #000;
    padding-left: 62px;
}

.t_name .s01 a {
    color: #000;
}

.t_name .s02 {
    color: #fd0467;
}

.t_name .s02 a {
    color: #fd0467;
}

.t_name .s01 a:hover,
.t_name .s02 a:hover {
    text-decoration: none;
}
