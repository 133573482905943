.tit02 {
    width: 650px;
    height: 65px;
    /* margin-top: 20px; */
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/titBg_0df47071.jpg) repeat-x;
    position: relative;
}

.t_name {
    font-family: Microsoft YaHei;
    font-size: 24px;
    color: #ddd;
    height: 65px;
    line-height: 65px;
}

.s01 {
    color: #000;
    padding-left: 59px;
}

.s01 a {
    color: #000;
}

.s02 {
    color: #fd0467;
}

.s02 a {
    color: #fd0467;
}

.s01 a:hover,
.s02 a:hover {
    text-decoration: none;
}

.t_name0 {
    width: 167px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/t2_0_827fce50.jpg) no-repeat;
    margin: 0 auto;
}

.t_name1 {
    width: 186px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/t2_1_b892d87b.jpg) no-repeat;
}

.t_name2 {
    width: 195px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/t2_2_61f95636.jpg) no-repeat;
}

.t_name3 {
    width: 197px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/t2_3_8500762a.jpg) no-repeat;
}

.t_name4 {
    width: 210px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/t2_4_8ff88825.jpg) no-repeat;
}

.t_name5 {
    width: 196px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/t2_5_66760829.jpg) no-repeat;
}

.t_name6 {
    width: 167px;
    background: url(http://p0.ifengimg.com/fe/fashion_ifeng_com/images/t2_6_bccc5434.jpg) no-repeat;
    margin: 0 auto;
}

.t_name0 .s01 {
    padding-left: 62px;
}

.t_name2 .s01 {
    padding-left: 68px;
}

.t_name3 .s01 {
    padding-left: 70px;
}

.t_name4 .s01 {
    padding-left: 49px;
}

.t_name5 .s01 {
    padding-left: 69px;
}

.t_name6 .s01 {
    padding-left: 62px;
}
